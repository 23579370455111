import { useRevalidator } from "@remix-run/react";
import { useEffect } from "react";
import { useEventSource } from "remix-utils/sse/react";
import {
  Replicache,
  type WriteTransaction,
} from "replicache";
import type { Mutators } from "./replicache.server";
import { mapIngredients } from "./mapIngredients";

export const rep = new Replicache({
  name: window.USER?.org||"public",
  licenseKey: "labc84834bd864e678cae28275fa722e0",
  pullURL: "/resources/replicache/pull",
  pushURL: "/resources/replicache/push",
	mutators: {
		async completeGroceries(
			tx: WriteTransaction,
			{ ids, orgId, complete },
		) {
			for (const id of ids) {
				const item = (await tx.get(`GroceryItem/${orgId}/${id}`)) as any;
				if (item) {
					await tx.put(`GroceryItem/${orgId}/${id}`, {
						...item,
						complete,
					});
				}
			}
		},
		async addGroceryItem(
			tx: WriteTransaction,
			{ orgId, id, ingredientId },
		) {
			await tx.put(`GroceryItem/${orgId}/${id}`, {
				id,
				ingredientId,
				orgId,
				complete: false,
			});
		},
		async addMealPlan(
			tx: WriteTransaction,
			{ mealPlanId, orgId, recipeId, userId },
		) {
			await tx.put(`MealPlan/${orgId}/${mealPlanId}`, {
				id: mealPlanId,
				recipeId,
				orgId,
				userId,
				groceriesAdded: false,
				cookedDate: null,
				scheduledDate: null,
			});
		},
		async addMealPlanGroceries(
			tx: WriteTransaction,
			{ mealPlanId, orgId, groceries },
		) {
			const mealPlan = (await tx.get(
				`MealPlan/${orgId}/${mealPlanId}`,
			)) as any;
			if (mealPlan.groceriesAdded) return;
			for (const value of groceries) {
				await tx.put(`GroceryItem/${orgId}/${value.id}`, {
					...value,
					complete: false,
				});
			}
			await tx.put(`MealPlan/${orgId}/${mealPlanId}`, {
				...mealPlan,
				groceriesAdded: 1,
			});
		},
		async cookMealPlan(
			tx: WriteTransaction,
			{ mealPlanId, orgId, cookedDate },
		) {
			const mealPlan = (await tx.get(
				`MealPlan/${orgId}/${mealPlanId}`,
			)) as object;
			await tx.put(`MealPlan/${orgId}/${mealPlanId}`, {
				...mealPlan,
				cookedDate,
			});
		},

		async scheduleMealPlan(
			tx: WriteTransaction,
			{ mealPlanId, orgId, date },
		) {
			const mealPlan = (await tx.get(
				`MealPlan/${orgId}/${mealPlanId}`,
			)) as object;
			await tx.put(`MealPlan/${orgId}/${mealPlanId}`, {
				...mealPlan,
				scheduledDate: date,
			});
		},
		async deleteMealPlan(tx: WriteTransaction, { mealPlanId, orgId }) {
			console.log(await tx.scan({ prefix: `MealPlan/` }).entries().toArray());
		console.log(	orgId, mealPlanId, await tx.del(`MealPlan/${orgId}/${mealPlanId}`));
			const items = (await tx
				.scan({ prefix: `GroceryItem/${orgId}` })
				.entries()
				.toArray()) as any[];
			const toDelete = items.filter(
				([key, item]) => item.mealPlanId === mealPlanId,
			);
			for (const [key] of toDelete) {
				await tx.del(key);
			}
		},
		async setRecipe(
			tx: WriteTransaction,
			{
				orgId,
				slug,
				steps,
				title,
				userId,
				categories,
				cookMinutes,
				description,
				published,
				public: recipePublic,
				image,
				prepMinutes,
				source,
				yield: recipeYield,
			},
		) {
			const cats = await tx
				.scan({ prefix: `RecipeCategory/${slug}` })
				.keys()
				.toArray();
			for (let c of cats) {
				await tx.del(c);
			}
			for (let category of categories || []) {
				await tx.put(`RecipeCategory/${slug}/${category}`, {
					recipeId: slug,
					categoryId: category,
				});
				await tx.put(`Category/${category}`, {
					slug: category,
					title: category,
				});
			}
			await tx.put(`Recipe/${slug}`, {
				slug,
				authorId: userId,
				title,
				image,
				description,
				prepMinutes,
				cookMinutes,
				source,
				yield: recipeYield,
				published,
				public:recipePublic,
				orgId,
			});
			const oldSteps = await tx
				.scan({ prefix: `RecipeStep/${slug}` })
				.keys()
				.toArray();
			for (let s of oldSteps) {
				await tx.del(s);
			}
			const [ingredients, ingredientMap] = mapIngredients(steps);
			let count = 0;
			for (let step of steps) {
				const sort = count++;
				await tx.put(`RecipeStep/${slug}/${step.id}`, {
					id: step.id,
					recipeId: slug,
					directions: step.directions,
					sort,
				});
			}
			const oldStepIngredients = await tx
				.scan({ prefix: `RecipeStepIngredient/${slug}` })
				.keys()
				.toArray();
			for (let s of oldStepIngredients) {
				await tx.del(s);
			}
			for (let ingredient of Object.values(ingredients)) {
				const oldIngredient = await tx.get(
					`Ingredient/${ingredient.slug}`,
				);
				await tx.put(`Ingredient/${ingredient.slug}`, {
					category: "Other",
					userProvided: 1,
					...(typeof oldIngredient === "object" ? oldIngredient : {}),
					slug: ingredient.slug,
					title: ingredient.title,
				});
			}
			for (let [, ingredient] of ingredientMap) {
				const ingredientObj = ingredients[ingredient.ingredientId];
				if (!ingredientObj || !ingredientObj.slug) continue;
				const oldRecipeSteps = await tx
					.scan({
						prefix: `RecipeStepIngredient/${slug}/${ingredient.stepId}`,
					})
					.keys()
					.toArray();
				for (let s of oldRecipeSteps) {
					await tx.del(s);
				}
				await tx.put(
					`RecipeStepIngredient/${slug}/${ingredient.stepId}/${ingredient.ingredientId}`,
					{
						recipeId: slug,
						stepId: ingredient.stepId,
						ingredientId: ingredientObj.slug,
						amount: ingredient.amount||"",
						unit: ingredient.unit||"",
						note: ingredient.note||"",
					},
				);
			}
		},
	} satisfies Mutators,
});

export function useReplicachePoke() {
  let poke = useEventSource("/resources/replicache/push", { event: "poke" });
  const { revalidate } = useRevalidator();

  useEffect(() => {
    if (poke) {
      rep.pull().then(() => revalidate());
    }
  }, [poke, revalidate]);

  useEffect(() => {
    let syncing = false;
    rep.onSync = (newSyncing) => {
      if (syncing && !newSyncing) {
        // We just got done with a sync operation.
        // Revalidate loader data
        revalidate();
      }
      syncing = newSyncing;
    };
  }, [revalidate]);
}
