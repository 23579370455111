import { slugify } from "./slugify";

export function mapIngredients(
  steps: {
    id: string;
    ingredients: {
      id: string;
      ingredient: string;
      amount?: string | null | undefined;
      note?: string | null | undefined;
      unit?: string | null | undefined;
    }[];
  }[],
) {
  return steps.reduce(
    (
      prev: [
        Record<string, { slug: string; title: string }>,
        Map<
          string,
          {
            stepId: string;
            ingredientId: string;
            amount?: string;
            unit?: string;
            note?: string;
          }
        >,
      ],
      next,
    ) => {
      for (let ingredient of next.ingredients) {
        const slug = slugify(ingredient.ingredient);
        prev[0][ingredient.id] = {
          slug,
          title: ingredient.ingredient,
        };

        prev[1].set(`${next.id}-${ingredient.id}`, {
          stepId: next.id,
          ingredientId: ingredient.id,
          amount: ingredient.amount || undefined,
          unit: ingredient.unit || undefined,
          note: ingredient.note || undefined,
        });
      }

      return prev;
    },
    [{}, new Map()],
  );
}
